<template>
<!-- eslint-disable max-len -->
  <div class="w-100 mt-2 pt-4 pr-3 col">
    <div class="row d-flex justify-items-center">
      <div class="col">
        <h5 class="text-center font-weight-bold">Wham app is using Stripe for an easy, smooth and secure payment management system</h5>
        <div class="card mt-5">
            <div class="card-body">
              <div>
                  <template v-if="isLoading">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="text-center spinner-border spinner-border-lg mr-2 text-info font-weight-bold" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div class="card-title d-flex justify-content-between align-items-center">
                      <img alt="Stripe logo" class="img-fluid logo" :src="stripeLogo">

                      <div class="mr-1">
                        <h6 class="font-weight-bold">Use Stripe Payments</h6>
                        <template v-if="isOnboarded">
                          <p class="mt-4 font-size-5">You are connected. Refresh your Stripe account to keep details upto-date</p>
                        </template>
                        <template v-else>
                          <p class="mt-4 font-size-5">Connect your Stripe account to accept payments through Stripe</p>
                        </template>
                      </div>

                      <template v-if="isOnboarded">
                        <button class="btn btn-sm bg-danger text-light"
                          :disabled="isLoading"
                          @click="onClickConnect"
                      >
                          <span>
                          REFRESH
                          </span>
                      </button>
                      </template>
                      <template v-else>
                      <button class="btn btn-sm bg-danger text-light"
                          :disabled="isLoading"
                          @click="onClickConnect"
                      >
                          <span>
                          CONNECT
                          </span>
                      </button>
                      </template>
                    </div>
                  </template>
              </div>
            </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {

  data() {
    return {
      // eslint-disable-next-line global-require
      stripeLogo: require('@/assets/images/stripe_logo.png'),
    };
  },

  created() {
    this.$store.dispatch('payments/getOnboardingStatus');
  },

  computed: {
    ...mapState('payments', {
      isOnboarded: 'isOnboarded',
      isLoading: 'paymentIsLoading',
    }),
  },

  methods: {
    onClickConnect() {
      this.$store.dispatch('payments/startOnboarding');
    },
  },

};
</script>

<style>
  .logo {
    max-width: 200px;
  }
  .font-size-5 {
    font-size: 14px;
  }

  @media (max-width: 994px) {
    .card-title {
      display: block !important;
      text-align: center;
    }
}

</style>
